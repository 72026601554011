import Vue from "vue";
import VueRouter from "vue-router";
import { constantRouterMap } from "@/config/router.config";

const routes = constantRouterMap;

Vue.use(VueRouter);
const router = new VueRouter({
  routes,
});

// 防止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
