import request from "@/utils/request";

const api = {
  getServiceList: "",
  login: "/user/api/login",
  logout: "/user/api/logout",
};

export function GetWorkers(parameter) {
  // return request({
  //   url: api.getServiceList,
  //   method: "post",
  //   params: parameter,
  // });
  console.log(parameter);
  return new Promise((resolve) => {
    const data = {
      message: "",
      result: {
        data: [
          {
            id: "1",
            w_id: "siteId13432",
            w_name: "北京",
            bc_name: "北京分公司",
            w_type: "1",
            w_sex: "男",
            w_contact: "11122223333",
            ws_names: "工地1，工地2，工地3",
            w_face: "1",
          },
        ],
        pageSize: 10,
        pageNo: 0,
        totalCount: 57,
      },
      status: 200,
    };
    resolve(data);
  });
}
export function DoLogin(parameter) {
  return request({
    url: api.login,
    method: "post",
    params: parameter,
  });
  // return new Promise((resolve) => {
  //   const data = {
  //     message: "",
  //     status: 200,
  //     Token: "wgfuiqgwetuyt847hgiuehg2039ur3ihgofh",
  //   };
  //   resolve(data);
  // });
}
export function DoLogout() {
  return request({
    url: api.logout,
    method: "post",
  });
}