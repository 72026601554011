import axios from "axios";
import store from "@/store";
import storage from "store";
import notification from "ant-design-vue/es/notification";
import { VueAxios } from "./axios";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { urlConfig } from "@/config/config";
import router from "../router";
import { message } from "ant-design-vue";

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  // baseURL: process.env.VUE_APP_API_BASE_URL,
  // baseURL: "http://192.168.1.194:8764",
  // baseURL: "http://127.0.0.1",
  // 域名端口：2443
  // baseURL: "http://123.56.117.58:8763/dsmcore",
  // baseURL: "http://123.56.117.58:8764",
  // baseURL: "http://123.56.117.58:8762",
  baseURL: urlConfig.baseUrl,
  timeout: 100000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data;
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN);
    if (error.response.status === 403) {
      notification.error({
        message: "Forbidden",
        description: data.message,
      });
    }
    if (
      error.response.status === 401 &&
      !(data.result && data.result.isLogin)
    ) {
      notification.error({
        message: "Unauthorized",
        description: "Authorization verification failed",
      });
      if (token) {
        store.dispatch("Logout").then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          localStorage.removeItem("userMsg");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("roles");

          localStorage.removeItem("path", "");
          localStorage.removeItem("SET_SYSTEM");
          this.$store.commit("SET_CURRENT", "");
          this.$store.commit("SET_SYSTEM", "");
        });
      }
    }
  }
  return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use((config) => {
  // const token = storage.get(ACCESS_TOKEN);
  let token = sessionStorage.getItem("token");
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers["token"] = token;
  }
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  // console.log("response", response);
  if (response.data.code == "500" && response.data.msg == "Token Expiration") {
    // 跳转到登录
    router.push({ path: "/Login" });
    return;
  } else if (
    response.data.code == "401" &&
    response.data.msg == "No Permission"
  ) {
    // 无权访问
    message.error("无权访问");
  }
  return response.data;
}, errorHandler);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request);
  },
};

export default request;

export { installer as VueAxios, request as axios };
