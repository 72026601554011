import { DoLogin } from "@/api/login";
import { GetUser } from "@/api/role";

const user = {
  state: {
    token: "",
    name: "",
    id: "",
    roles: [], //现场管理系统菜单权限
    info: {},
    isShowBtns: [], //现场管理系统按钮权限
    rolesJF: [], //交付系统菜单权限
    isShowBtnsJF: [], //交付系统按钮权限
    system: "",
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_ID: (state, id) => {
      state.id = id;
      // console.log(state);
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
      // console.log("roles", roles);
      // console.log(sessionStorage.getItem("roles"));
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_BTNS: (state, isShowBtns) => {
      state.isShowBtns = isShowBtns;
    },
    SET_ROLESJF: (state, rolesJF) => {
      state.rolesJF = rolesJF;
    },
    SET_BTNSJF: (state, isShowBtnsJF) => {
      state.isShowBtnsJF = isShowBtnsJF;
    },
    SET_SYSTEM: (state, system) => {
      state.system = system;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        DoLogin(userInfo)
          .then((response) => {
            if (response.code == "200") {
              sessionStorage.setItem("token", response.data);
              commit("SET_TOKEN", response.data);
              resolve();
              //获取当前用户的信息
              GetUser().then((res) => {
                commit("SET_ROLES", res.data.r_right);
                commit("SET_NAME", res.data.u_name);
                commit("SET_ID", res.data.u_id);
                commit("SET_BTNS", res.data.r_interface);
                commit("SET_ROLESJF", res.data.r_right_jf);
                commit("SET_BTNS", res.data.r_interface_jf);

                // console.log(res.data.r_right.split(","));
                // console.log(res.data.r_name);
                sessionStorage.setItem("r_name", res.data.r_name);
                sessionStorage.setItem("roles", res.data.r_right);
                sessionStorage.setItem("isShowBtns", res.data.r_interface);
                sessionStorage.setItem("isShowBtnsJF", res.data.r_interface_jf);
              });
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    // GetInfo({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     getInfo()
    //       .then((response) => {
    //         const result = response.result;

    //         if (result.role && result.role.permissions.length > 0) {
    //           const role = result.role;
    //           role.permissions = result.role.permissions;
    //           role.permissions.map((per) => {
    //             if (
    //               per.actionEntitySet != null &&
    //               per.actionEntitySet.length > 0
    //             ) {
    //               const action = per.actionEntitySet.map((action) => {
    //                 return action.action;
    //               });
    //               per.actionList = action;
    //             }
    //           });
    //           role.permissionList = role.permissions.map((permission) => {
    //             return permission.permissionId;
    //           });
    //           commit("SET_ROLES", result.role);
    //           commit("SET_INFO", result);
    //         } else {
    //           reject(new Error("getInfo: roles must be a non-null array !"));
    //         }

    //         commit("SET_NAME", { name: result.name, welcome: welcome() });

    //         resolve(response);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },

    // 登出
    // Logout({ commit, state }) {
    //   return new Promise((resolve) => {
    //     logout(state.token)
    //       .then(() => {
    //         commit("SET_TOKEN", "");
    //         commit("SET_ROLES", []);
    //         storage.remove(ACCESS_TOKEN);
    //         resolve();
    //       })
    //       .catch((err) => {
    //         console.log("logout fail:", err);
    //         // resolve()
    //       })
    //       .finally(() => {});
    //   });
    // },
  },
};

export default user;
