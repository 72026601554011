import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { message } from "ant-design-vue";
import "@/config/antdLazyUse"; // 按需引入antd组件
import "@/assets/global.less";
import "@/assets/iconfont/iconfont.css";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn"); // 中文化
import md5 from "md5";
import BaiduMap from "vue-baidu-map";
import global from "./utils/global";
import { BmlMarkerClusterer } from "vue-baidu-map";
import VueLazyload from "vue-lazyload"; //图片懒加载
Vue.component("bml-marker-clusterer", BmlMarkerClusterer);
// 全局过滤器
import * as filters from "./utils/filters.js";
// 放大镜功能
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
// 记录用户访问页面及访问次数
let ToRouter = [
  { index: 0, title: "工地列表", name: "SiteList" },
  { index: 1, title: "考勤统计", name: "AttendStatistics" },
  { index: 2, title: "设备统计", name: "DeviceStatistics" },
  { index: 3, title: "设备列表", name: "DeviceList" },
  { index: 4, title: "报警事件管理", name: "AlarmEvent" },
  { index: 5, title: "人员考勤", name: "WorkerAttend" },
  { index: 6, title: "日报", name: "DailySheet" },
];
// let ToRouter;
// let ToRouter = JSON.parse(sessionStorage.getItem("ToRouter"));
if (JSON.parse(sessionStorage.getItem("ToRouter"))) {
  ToRouter = JSON.parse(sessionStorage.getItem("ToRouter"));
} else {
  // ToRouter = [];
}

router.beforeEach((to, from, next) => {
  // console.log(to, from, next);

  if (to.meta.requireAuth === true && !getToken()) {
    next({
      name: "Login",
    });
  } else {
    const routerData = new Object();
    routerData.router = new Object();
    routerData.router.name = to.name;
    routerData.router.params = to.params;
    routerData.router.query = to.query;
    routerData.title = to.meta.title;
    routerData.name = to.name;
    routerData.memoryRouter = to.meta.memoryRouter;
    routerData.num = 0;
    // console.log("routerData", routerData);
    if (
      ToRouter.length == 0 &&
      routerData.name != "Login" &&
      routerData.memoryRouter != false
    ) {
      ToRouter.push(routerData);
    } else {
      for (var i = 0; i < ToRouter.length; i++) {
        if (
          ToRouter[i].title == routerData.title &&
          routerData.memoryRouter != false
        ) {
          ToRouter[i].num++;
          break;
        }
        if (
          i == ToRouter.length - 1 &&
          routerData.name != "Login" &&
          routerData.memoryRouter != false
        ) {
          // if (routerData.name != "Login") {
          ToRouter.push(routerData);
          // }
          break;
        }
      }
    }
    // console.log("ToRouter", ToRouter);
    sessionStorage.setItem("ToRouter", JSON.stringify(ToRouter));
    next();
  }
});
// 全局路由守卫
Vue.config.productionTip = false;
Vue.prototype.$message = message;
Vue.prototype.$md5 = md5;
Vue.prototype.$moment = moment;

Vue.use(BaiduMap, {
  // ak: "X4FIZhcPdZiRVnPePW0PGhZhniDwrelU",
  // ak: "TeUeOXzC6E7HEUNQpPBu0yWHxozF57B8",
  // ak: "5w1gBInHWqHp3NBgANGrxBIriTdvirhh",
  ak: "z1zWV2F71OqGbn192Q9TKDdFQczEK7GN",
});
Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: require("../src/assets/images/user_img.png"),
  loading: require("../src/assets/images/imgloading.gif"),
  attempt: 1,
});

Vue.use(Viewer);

Vue.use(global);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
