import md5 from "md5";
import moment from "moment";
export default {
  install(Vue) {
    // 入参MD5加密
    Vue.prototype.$verify = function (value, order) {
      let str = "";
      order.forEach((el) => {
        str = str + el + (value[el] ? value[el] : "") + ",";
      });
      return md5(str);
    };
    // 查询参数排序，需要与接口文档中提供的加密顺序对应
    Vue.prototype.$order = function (params, order) {
      let obj = {};
      order.forEach((el) => {
        obj[el] = params[el] ? params[el] : "";
      });
      return obj;
    };
    /**
     * 时间格式化方法
     * 入参date返回日期[年-月-日]
     * 入参time单位返回时间[时:秒]
     * 没有入参默认返回日期和时间[年-月-日 时:分:秒]
     *  */
    Vue.prototype.$Format = function (time, type) {
      if (!time) {
        return "--";
      }
      let date = new Date(time * 1000);
      let f = "";
      if (type === "date") {
        f = "YYYY-MM-DD";
      } else if (type === "time") {
        f = "HH:mm";
      } else if (type === "times") {
        f = "HH:mm:ss";
      } else {
        f = "YYYY-MM-DD HH:mm:ss";
      }
      let mom = moment(date).format(f);
      return mom;
    };
    Vue.prototype.$PicName = function (time, type) {
      if (!time) {
        return "--";
      }
      let date = new Date(time * 1000);
      let f = "";

      f = "YYYYMMDD-HHmmss";

      let mom = moment(date).format(f);
      return mom;
    };
    /**
     * 计算时长方法
     * 入参time单位为秒
     * type后续补充计算到不同单位级别（年/月/日）
     *  */
    Vue.prototype.$calcDate = function (time, type) {
      if (!time) {
        return "-";
      }
      let days = Math.floor(time / (3600 * 24));
      return days + "天";
    };
    /* 转化为时分秒 */
    Vue.prototype.$toHHmmss = function (data) {
      var time;
      var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = (data % (1000 * 60)) / 1000;
      time =
        (hours < 10 ? "0" + hours : hours) +
        " h " +
        (minutes < 10 ? "0" + minutes : minutes) +
        "m";
      // +
      // (seconds < 10 ? "0" + seconds : seconds);
      return time;
    };

    //获取日期周开始日期（周一为每周的开始,周日为每周的结束）
    Vue.prototype.$beginOfWeek = function (dateValue) {
      let date;
      if (dateValue instanceof Date) {
        date = dateValue;
      } else {
        date = new Date(dateValue);
      }
      let subDay = 0;
      let weekDay = date.getDay();
      if (weekDay == 0) {
        //周天
        subDay = 6;
      } else {
        subDay = weekDay - 1;
      }
      let beginDateTime = date.getTime() - 86400000 * subDay;
      // return new Date(beginDateTime);
      return parseInt(beginDateTime / 1000);
      // console.log(new Date(beginDateTime));
      // console.log(beginDateTime);
    };
    //获取日期周结束日期（周一为每周的开始，周日为每周的结束）
    Vue.prototype.$endOfWeek = function (dateValue) {
      let date;
      if (dateValue instanceof Date) {
        date = dateValue;
      } else {
        date = new Date(dateValue);
      }
      let weekDay = date.getDay();
      if (weekDay == 0) {
        //周天
        return date;
      } else {
        let addDay = 7 - weekDay;
        let endDateTime = date.getTime() + 86400000 * addDay;
        // return new Date(endDateTime);
        // console.log(new Date(endDateTime));
        // console.log(endDateTime);
        return parseInt(endDateTime / 1000);
      }
    };
    // 获取指定日期所在月份的第一天
    Vue.prototype.$beginOfMonth = function (value) {
      let date = new Date(value);
      let new_year = date.getFullYear(); //取当前的年份
      let month = date.getMonth();
      let new_month = month; //取当前的月份
      let d = new Date(new_year, new_month, 1).getTime() / 1000; //取当年当月中的第一天
      return d;
    };
    // 获取指定日期所在月份的最后一天
    Vue.prototype.$endOfMonth = function (value) {
      let date = new Date(value);
      let new_year = date.getFullYear(); //取当前的年份
      let month = date.getMonth();
      let new_month = month + 1; //取当前的月份
      let d = new Date(new_year, new_month, 1); //取当年当月中的第一天
      // 如果一个日期格式和一个毫秒相减，会变成一个时间毫秒戳
      let lastDay =
        new Date(d.getTime() - 1000 * 60 * 60 * 24).getTime() / 1000; //获取当月最后一天日期
      // console.log(d, lastDay);
      return lastDay;
    };
  },
};
